<template>
    <BaseModal
        :modalWidth="'520px'"
        class="reconciliation-modal-mass-edit"
        @close="onCloseModal"
    >
        <template #modalBody>
            <div class="reconciliation-modal-mass-edit__body">
                <div class="reconciliation-modal-mass-edit__section">
                    <div class="reconciliation-modal-mass-edit__title">
                        Массовое редактирование
                    </div>
                    <div class="reconciliation-modal-mass-edit__subtitle">
                        {{ modalSubtitle }}
                    </div>
                </div>
                <div class="reconciliation-modal-mass-edit__section">
                    <BaseSelectV2
                        canSearch
                        isFullWidth
                        isSimpleCustomTitle
                        :value="optionToEditId"
                        :options="optionsToEdit"
                        :defaultTitle="optionToEditId"
                        label="Выберите параметры для редактирования"
                        class="reconciliation-modal-mass-edit__select"
                        @check="onCheckOptionToEdit"
                    />
                </div>

                <div
                    v-if="optionToEditData"
                    class="reconciliation-modal-mass-edit__section"
                >
                    <BaseSelectV2
                        v-if="isOptionToEditSelect"
                        canSearch
                        isFullWidth
                        isSimpleCustomTitle
                        :value="optionToEditData.value"
                        :options="optionToEditData.options"
                        :defaultTitle="optionToEditData.value"
                        label="Введите значение для редактирования"
                        class="reconciliation-modal-mass-edit__select"
                        @onSearchValue="onSearchOptions"
                        @lazyload="onLazyLoadOptions"
                        @onToggleDropdown="onToggleDropdown"
                        @check="onCheckValueToEdit"
                    />
                    <BaseInputV2
                        v-else
                        :id="optionToEditData.prop"
                        :type="optionToEditData.type"
                        :value="optionToEditData.value"
                        :label="optionToEditData.label"
                        placeholder="Введите значение"
                        class="reconciliation-modal-mass-edit__input"
                        @onInput="onInputValueToEdit"
                    />
                </div>
                <div class="reconciliation-modal-mass-edit__section">
                    <div class="reconciliation-modal-mass-edit__actions">
                        <div class="reconciliation-modal-mass-edit__action">
                            <BaseButton view="border" @click="onCloseModal">
                                Отменить
                            </BaseButton>
                        </div>
                        <div class="reconciliation-modal-mass-edit__action">
                            <BaseButton
                                :disabled="
                                    optionToEditId && !optionToEditData.value
                                "
                                view="secondary"
                                @click="onSaveChanges"
                            >
                                Сохранить
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from "../../Base/BaseModal.vue";
import BaseSelectV2 from "../../Base/BaseSelectV2.vue";
import BaseInputV2 from "../../Base/BaseInputV2.vue";
import BaseButton from "../../Base/BaseButton.vue";
import UtilNumber from "../../../utils/UtilNumber";

export default {
    name: "ReconciliationModalMassEdit",
    components: {
        BaseModal,
        BaseSelectV2,
        BaseButton,
        BaseInputV2,
    },
    props: {
        selectedCount: {
            type: Number,
            default: 0,
        },
        tableRowsToEdit: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            optionToEditId: "",
        };
    },
    computed: {
        modalSubtitle() {
            return `Выбрано: ${UtilNumber.toLocaleNum(this.selectedCount)}`;
        },
        optionsToEdit() {
            return this.tableRowsToEdit.editableFields.map((item) => ({
                id: item.prop,
                value: item.label,
            }));
        },
        optionToEditData() {
            return this.tableRowsToEdit.editableFields.find(
                (item) => String(item?.prop) === String(this.optionToEditId)
            );
        },
        isOptionToEditSelect() {
            return String(this.optionToEditData?.type) === "select";
        },
    },
    methods: {
        onCloseModal() {
            this.$emit("onCloseModal");
        },
        onSaveChanges() {
            this.onCloseModal();
            this.$emit("onSaveChanges");
        },
        onCheckOptionToEdit(item) {
            this.optionToEditId = item.id;
        },
        onCheckValueToEdit(option) {
            this.$emit("onChangeRowValue", {
                value: option.id,
                select: this.optionToEditData,
            });
        },
        onInputValueToEdit(value) {
            this.$emit("onChangeRowValue", {
                value,
                select: this.optionToEditData,
            });
        },
        onSearchOptions(value) {
            this.$emit("onSearchOptions", {
                query: value,
                field: this.optionToEditData.prop,
            });
        },
        onLazyLoadOptions() {
            this.$emit("onLazyLoadOptions", this.optionToEditData);
        },
        onToggleDropdown(isOpen) {
            if (isOpen && !this.optionToEditData.options.length) {
                this.$emit("onFetchOptions", this.optionToEditData);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.reconciliation-modal-mass-edit {
    &__section {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    &__subtitle {
        &_select {
            margin-bottom: 10px;
        }
    }

    &__actions {
        display: flex;
        justify-content: end;
    }

    &__action {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}
</style>
